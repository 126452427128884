@import src/styles/colors
@import src/styles/variables

.message
    pointer-events: auto
    position: absolute
    bottom: 0
    right: 80px
    padding: 15px 15px 15px 15px
    max-width: 280px
    min-width: 280px
    background-color: $white
    box-shadow: 0 0 30px rgba(0,0,0,.05)
    border: 1px solid transparent
    border-radius: 5px
    color: $black
    display: flex
    justify-content: flex-start
    align-items: center
    line-height: 1
    height: max-content
    max-height: 150px
    overflow: hidden
    transition: all 350ms cubic-bezier(.4,.2,0,1)

    &:before
        content: " "
        display: block
        width: 100%
        height: 100%
        position: absolute
        left: 0
        bottom: 0
        z-index: -1
        background-color: $white

    > .text
        padding-left: 25px
        color: $black
        line-height: 1.5
        width: max-content
        height: max-content
        max-height: 150px
        overflow-y: auto
        overflow-x: hidden
        transition: all 350ms cubic-bezier(.4,.2,0,1)

        &.dismissible
            padding: 0 50px 0 25px

    .status-dot
        content: ""
        min-width: 10px
        min-height: 10px
        background-color: $red
        margin-right: 15px
        border-radius: 20px
        display: flex
        justify-content: center
        align-items: center
        position: absolute
        animation: blob 2s cubic-bezier(0.76, 0, 0.24, 1) infinite
        transition: all 350ms cubic-bezier(.4,.2,0,1)

        &:before
            content: ""
            display: inline-block
            min-width: 30px
            min-height: 30px
            border-radius: 50px
            background-color: $red
            opacity: .03
            position: absolute
            left: 50%
            top: 50%
            transform: translate(-50%,-50%)
            transition: all 350ms cubic-bezier(.4,.2,0,1)

        &:after
            content: ""
            display: inline-block
            min-width: 50px
            min-height: 50px
            margin-right: 10px
            border-radius: 50px
            background-color: $red
            opacity: .02
            position: absolute
            left: 50%
            top: 50%
            transform: translate(-50%,-50%)
            transition: all 350ms cubic-bezier(.4,.2,0,1)

    &.success
        &:before
            background: linear-gradient(90deg, $green-light -5px, transparent 50px)
        border: 1px solid $green
        .status-dot
            background-color: $green
            &:before
                background-color: $green
            &:after
                background-color: $green
    &.danger
        &:before
            background: linear-gradient(90deg, $red-light -5px, transparent 50px)
        border: 1px solid $red
        .status-dot
            background-color: $red
            &:before
                background-color: $red
            &:after
                background-color: $red

    &.warning
        &:before
            background: linear-gradient(90deg, $yellow-light -5px, transparent 50px)
        border: 1px solid $yellow
        .status-dot
            background-color: $yellow
            &:before
                background-color: $yellow
            &:after
                background-color: $yellow


    .icon-container
        padding: 27px
        cursor: pointer
        position: fixed
        top: 0
        right: 0
        display: flex
        justify-content: center
        align-items: center

        span
            font-size: 18px
            color: $black
            line-height: 0
            width: 0
            height: 0
            display: flex
            justify-content: center
            align-items: center

@keyframes messageIn
    to
        bottom: 25px
        box-shadow: 0 10px 50px rgba(0,0,0,.05)

@keyframes messageOut
    to
        opacity: 0

@keyframes blob
    50%
        transform: scale(.8)