@import src/styles/colors
@import src/styles/variables

.start
    .power-bi-embed-window
        height: 100%
        width: 100%
        margin: 0
        padding: 0


    .no-reports
        width: 100%
        height: 100%
        display: flex
        justify-content: center
        align-items: center

        > span
            font-size: 15vw
            color: $grey-light
            @include disable-selecting