@import "colors"
@import "variables"

*
    box-sizing: border-box
    margin: 0
    padding: 0
    //font-family: $font-family
    -webkit-user-drag: none

    ::-webkit-scrollbar
        cursor: pointer
        width: 8px
        height: 8px
        background: rgba($black-rgb, .0)

    ::-webkit-scrollbar-thumb
        transition: all 350ms $animationCurve
        background: rgba($black-rgb, .3)
        border-radius: 2px

        &:hover
            background: rgba($black-rgb, .6)


    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button
        -webkit-appearance: none
        margin: 0

    &:focus
        outline: none

    &::selection
        background: $selection-color
        color: $selection-text-color

    &::-moz-selection
        background: $selection-color
        color: $selection-text-color


.material-symbols-outlined
  font-variation-settings: 'FILL' 0, 'wght' 600, 'GRAD' 0, 'opsz' 48

a
    text-decoration: none
    color: inherit

.modal-body
    .content
        .select
            width: 100%
            > div
                width: 100%
                .button
                    width: 100% !important
                    border-radius: 5px
                    justify-content: space-between
                    padding: 13px 15px 13px 10px
                    font-size: 13.5px
                    border: 1px solid $black
                    background-color: $white
            &.active
                .button
                    border-color: $base-color
                    background-color: rgba($base-color-rgb, .05)


        .textfield
            input
                background-color: $white !important
                border-color: $black !important
                border-radius: 5px !important
                color: $black !important
                font-weight: normal !important
                font-size: 13.5px !important

                &:focus
                    border-color: $base-color !important
                    background-color: rgba($base-color-rgb, .05) !important

