@import "src/styles/colors"
@import "src/styles/variables"

.textfield
  position: relative
  display: flex
  align-items: center

  .textfield-input
    box-sizing: content-box
    width: calc(100% - 21px)
    border: 1px solid rgba($black-rgb, .15)
    background-color: rgba($black-rgb, .05)
    padding: 0 10px
    line-height: 40.5px
    border-radius: 5px
    font-size: 15px
    color: $black
    transition: all 250ms cubic-bezier(.4, .2, 0, 1)
    font-weight: 500
    font-family: $font-family

    &:-webkit-autofill
      -webkit-text-fill-color: $grey
      transition: background-color 5000000s ease-in-out 0s

    &.textarea
      line-height: 20px
      padding: 10px
      min-height: min-content

    &.with-left-icon
      padding-left: 43px

      &-button
        padding-left: 52px

    &.with-right-icon-button
      padding-right: 43px

      &-button
        padding-right: 52px

    &:focus
      border: 1px solid rgba($blue-rgb, .8)
      background-color: rgba($blue-light-rgb, .2)
      color: $blue-dark

  .icon-button
    position: absolute
    padding: 0 !important
    height: 43px
    width: 43px
    transform: scale(1)

    .tv-tool-button
      background-color: $white
      border: 1px solid rgba($text-field-button-border-color, $text-field-button-border-alpha)

    > span
      color: rgba($blue-rgb, .7)
      font-size: 20px

    &.only-icon
      cursor: default
      background-color: transparent
      box-shadow: 0 0 0 transparent
      pointer-events: none

    &.left
      left: 0

    &.right
      right: 0

  &.is-invalid
    .textfield-input
      color: $red
      background-color: rgba($red-light-rgb, .2)
      border-color: $red !important
    .tv-tool-button
      span
        color: $red !important

  .textfield-invalid-feedback-text
    color: $red
    position: absolute
    left: 0
    transform: translateY(200%)
    font-size: 12px
