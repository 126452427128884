@import "src/styles/variables"
@import "src/styles/colors"

$select-options-pase-color: $black
$select-options-pase-color-rgb: $black-rgb

$background-effect-lines: repeating-linear-gradient(45deg, rgba($select-options-pase-color-rgb, .2) 1px, rgba($select-options-pase-color-rgb, .2) 2px, transparent 2px, transparent 7px)

.select-options
    @include disable-selecting
    position: fixed
    font-family: $font-family
    display: flex
    flex-direction: column
    justify-content: flex-start
    padding: 15px 0 15px 15px
    background-color: $white
    border-radius: 10px
    border: 1px solid $select-options-pase-color
    min-width: 250px
    width: max-content
    max-height: 300px
    box-shadow: 0 15px 50px rgba(#000, .1)
    box-sizing: border-box
    opacity: 0
    z-index: 9999999999
    overflow: hidden
    top: 0
    left: 0
    pointer-events: none

    > div
        height: 100%
        display: contents

    .disable-pointer-events-layer
        position: absolute
        width: 100%
        height: 100%
        top: 0
        left: 0
        z-index: 50
        display: block

    .tv-tool-button
        box-shadow: 0 5px 20px rgba(0, 0, 0, .04) !important
        border: 1px solid rgba($select-options-pase-color-rgb, .75) !important

        > span
            color: rgba($black-rgb, .5)
            -webkit-text-stroke: 0 $black

    &:before
        content: " "
        display: block
        position: absolute
        background: $white
        height: 100%
        width: 100%
        z-index: -1
        top: 0
        left: 0
        background: linear-gradient(180deg, $white -51px, $white 70px, $white 70px), $background-effect-lines

    .select-options-body
        display: contents

        .select-options-column
            position: relative

            .empty-listview-background-image
                opacity: 0
                position: absolute
                height: calc(100% + 30px)
                width: 100%
                top: 0
                left: 0
                margin-top: 15px
                display: flex
                justify-content: center
                align-items: center

                > span
                    font-size: 50px
                    color: rgba($select-options-pase-color-rgb, .75)

            .options-list
                padding-right: 15px
                position: relative
                overflow-x: hidden
                width: 100%
                min-height: 100%
                max-height: 100%
                height: 100%
                overflow-y: scroll

    .select-options-headline
        position: relative
        width: calc(100% + 15px)
        left: -15px
        top: -15px
        overflow: hidden
        line-height: 50px
        text-align: center
        font-weight: bolder
        font-size: 17px
        color: $select-options-pase-color
        margin-bottom: 0
        display: flex
        justify-content: center
        align-items: center

        > span
            width: 100%

    &.show-gradient-effect
        &:before
            background: linear-gradient(180deg, transparent -51px, $white 70px), $background-effect-lines

        &.with-search
            &:before
                background: linear-gradient(180deg, transparent -51px, $white 70px), $background-effect-lines

        &.with-header-text
            &:before
                background: linear-gradient(180deg, transparent -51px, $white 60px), $background-effect-lines

            &.with-search
                &:before
                    background: linear-gradient(180deg, transparent -51px, $white 130px), $background-effect-lines

        &.multi-select
            &.show-selected-parallel
                &:before
                    background: linear-gradient(180deg, transparent -51px, $white 150px), $background-effect-lines

    .select-options-actions
        display: flex
        justify-content: space-between
        margin-right: 15px
        overflow: hidden

        .select-options-search-field
            width: calc(100% - 55px)
            height: max-content
            min-height: max-content
            margin-bottom: 15px
            position: relative
            margin-right: -40px

            &:focus
                background-color: $white
                border-color: $blue

            .select-all-buttons
                display: flex
                justify-content: center
                align-items: center
                position: absolute
                border-radius: 10px
                border: 1px solid rgba($select-options-pase-color-rgb, .75) !important
                background-color: $white
                overflow: hidden
                z-index: 22
                background: repeating-linear-gradient(45deg, rgba($black-rgb, .05) 1px, rgba($black-rgb, .05) 2px, transparent 2px, transparent 7px), $white

                .select-all-button
                    padding: 19px 20px !important
                    box-shadow: 0 0 0 transparent !important
                    background-color: transparent !important
                    max-height: 0
                    height: 0
                    min-width: 0
                    width: 0
                    border: 0
                    z-index: 21
                    opacity: 1
                    position: relative
                    border-width: 0
                    border-radius: 0
                    border-color: transparent !important

                    &:active
                        box-shadow: 0 0 10px $background-color

                    .button-icon
                        font-size: 15px
                        width: 0
                        max-height: 0
                        line-height: 40px
                        padding: 0
                        color: $black

                    &:first-child
                        border-right-color: rgba($select-options-pase-color-rgb, .75) !important

            &.hide
                opacity: 0

            input
                width: calc(100% - 40px)
                min-width: calc(100% - 40px)
                box-sizing: border-box
                line-height: 40px
                font-size: 15px
                border: 1px solid rgba($select-options-pase-color-rgb, .75)
                position: relative
                z-index: 20
                background-color: $white
                padding-left: 95px
                padding-right: 40px
                border-radius: 10px

            .clear-button
                position: absolute
                box-shadow: 0 0 0 transparent !important
                border-color: transparent !important
                background-color: transparent !important
                padding: 20px !important
                top: .5px
                width: 0
                height: 0
                right: 0
                z-index: 22
                border-radius: 0
                color: $black
                font-weight: normal
                line-height: 0

        .close-button
            box-shadow: 0 0 0 transparent !important
            border-color: rgba($green-rgb, .5) !important
            padding: 0 0 !important
            top: 0
            width: 42px
            height: 41px
            min-width: 42px
            min-height: 41px
            margin-left: 10px
            right: 0
            z-index: 22
            border-radius: 10px
            color: $black
            font-weight: normal
            line-height: 0
            background: repeating-linear-gradient(45deg, rgba($green-rgb, .15) 1px, rgba($green-rgb, .15) 2px, transparent 2px, transparent 7px), $white

            .button-icon
                font-size: 15px
                width: 0
                max-height: 0
                line-height: 40px
                padding: 0
                color: $green
                font-variation-settings: 'FILL' 0, 'wght' 800, 'GRAD' 0, 'opsz' 48

    &.single-select
        .select-options-column
            display: inherit

    &.multi-select
        &:not(.show-selected-parallel)
            .select-options-column
                display: inherit

    &:not(.show-selected-parallel)
        .select-options-body

            .select-options-column
                height: 100%

    &.show-selected-parallel
        max-width: max-content
        max-height: 450px
        width: 100%
        height: 100%

        > div
            display: block

        .select-options-body
            height: 90%
            width: calc(100% + 15px)
            display: flex
            align-items: flex-end
            padding-bottom: 15px
            position: relative
            left: -15px

            .select-options-column
                height: 100%
                width: 50%
                overflow: hidden

                .empty-listview-background-image
                    transition: opacity 750ms cubic-bezier(.4, .2, 0, 1)
                    opacity: 1
                    top: -15px
                    left: -7.5px
                    right: -7.5px

                    &.hide
                        opacity: 0

                .search-field-parallel-view
                    width: 100%
                    margin-right: -40px
                    position: relative
                    z-index: 20
                    border-radius: 20px !important
                    box-sizing: border-box

                    input
                        position: absolute
                        width: 100%
                        line-height: 40px
                        font-size: 15px
                        border: 1px solid rgba($select-options-pase-color-rgb, .75)
                        position: relative
                        z-index: 20
                        background-color: $white
                        border-radius: 10px
                        padding-right: 40px

                    .clear-button
                        position: absolute
                        box-shadow: 0 0 0 transparent !important
                        border-color: transparent !important
                        background-color: transparent !important
                        padding: 19px 20px !important
                        top: .5px
                        width: 0
                        height: 0
                        right: 0
                        z-index: 22
                        border-radius: 0
                        color: $black
                        font-weight: normal
                        line-height: 0

                .options-list
                    $content-height: calc(100% - 60px)
                    padding-right: 0
                    width: 100%
                    min-height: $content-height
                    max-height: $content-height
                    height: $content-height
                    margin-top: 15px
                    overflow-y: scroll
                    transition: max-width 350ms cubic-bezier(.4, .2, 0, 1) 550ms, min-width 350ms cubic-bezier(.4, .2, 0, 1) 550ms

                    &.hovered
                        max-width: calc(80% - 18px) !important
                        min-width: calc(80% - 18px) !important

                    &.not-hovered
                        max-width: 20% !important
                        min-width: 20% !important

                &:first-child
                    .search-field-parallel-view
                        padding-left: 15px

                    .options-list
                        direction: rtl
                        padding-left: 10px

                        > div
                            direction: ltr

                &:last-child
                    .search-field-parallel-view
                        padding-right: 15px

                        .clear-button
                            right: 15px

                    .options-list
                        padding-right: 10px
                        margin-right: -15px

            .vertical-rule
                margin: 0 10px
                height: 100%
                position: relative
                bottom: 0
                width: 1px
                padding: 0 1px
                background: linear-gradient(180deg, transparent 60px, rgba($blue-rgb, .2) calc(50% + 60px), transparent 100%)

        &.with-header-text
            .options-list
                $content-height: calc(100% - 90px) !important
                min-height: $content-height
                max-height: $content-height
                height: $content-height