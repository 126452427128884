@import src/styles/colors
@import src/styles/variables

.messages
    width: 100%
    height: 100%
    pointer-events: none
    position: fixed
    bottom: 0
    right: 0
    z-index: 9999999
    display: flex
    justify-content: center
    transition: all 350ms cubic-bezier(.4,.2,0,1)
    background-color: transparent
    @include disable-selecting

    &.show
        pointer-events: auto
        background-color: rgba($white-rgb, .8)

    .messages-bottom-action-buttons
        width: 280px
        height: max-content
        bottom: 90px
        display: flex
        justify-content: center
        pointer-events: none
        opacity: 0
        transition: all 350ms cubic-bezier(.4,.2,0,1)
        z-index: 60

        .ungroup-messages
            width: 38px
            height: 38px
            border-radius: 100px

        &.show
            bottom: 100px
            pointer-events: auto
            opacity: 1

    .messages-top-action-buttons
        bottom: 0
        right: 20px
        opacity: 0 !important
        display: flex
        justify-content: space-between
        position: absolute
        width: 280px
        height: 40px
        transition: all 350ms cubic-bezier(.4,.2,0,1)

        &.show
            bottom: 20px
            pointer-events: auto
            opacity: 1 !important

            &.disabled
                opacity: .3 !important

        .group-messages
            width: 40px

        .clear-all-messages-button