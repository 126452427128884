@import src/styles/colors
@import src/styles/variables

.switcher
    @include disable-selecting
    width: max-content
    background-color: $white
    border: 1px solid $grey
    border-radius: 30px
    position: relative
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer

    .icon
        padding: 10px 14px
        font-size: 12px
        color: $grey
        line-height: 0
        width: 0
        display: flex
        justify-content: center
        align-items: center
        z-index: 2
        @include disable-selecting
        transition: all 350ms cubic-bezier(.4,.2,0,1) 350ms

        &.active
            color: $white
            transform: scale(1.3)

        &.left

        &.right

    .background-select-effect
        padding: 10px
        max-width: 0
        max-height: 0
        background-color: $black
        position: absolute
        left: 4px
        right: 4px + 28px
        border-radius: 20px
        transition: all 350ms cubic-bezier(.4,.2,0,1) 250ms
        z-index: 1
        transform: scale(1.5) rotateZ(90deg)
        box-shadow: 0 5px 10px rgba($black-rgb, .1)
        background-image: url("/../public/button_background.png")
        background-size: 100%
        background-position: top

        &.active
            transition: all 350ms cubic-bezier(.4,.2,0,1) 250ms
            left: 4px + 28px
            right: 4px

    &.vertical
        flex-direction: column
        .icon
            padding: 14px 10px

        .background-select-effect
            left: auto
            right: auto
            top: 4px
            bottom: 4px + 28px

            &.active
                left: auto
                right: auto
                top: 4px + 28px
                bottom: 4px
