@import src/styles/colors
@import src/styles/variables

.create-new-account-modal
    width: 350px

    &.hide-close-button
        .tv-tool-modal-bottom-buttons
            .button
                &:first-child
                    opacity: 0
                    pointer-events: none

    .tv-tool-modal-body
        overflow: hidden
        display: flex
        flex-direction: column
        gap: 10px

        > .select
            > div
                width: 100% !important

                .button
                    width: 100%
                    justify-content: flex-start
                    display: flex
                    align-items: center
                    padding: 11px 10px
                    padding-left: 15px !important
                    color: $black
                    font-size: 15px
                    font-weight: 500
                    font-family: $font-family
                    background-color: rgba($black-rgb, 0.05)
                    border: 1px solid rgba($black-rgb, 0.15) !important
                    border-radius: 5px

        .checkbox
            width: 100%
            border: 1px solid rgba($black-rgb, 0.15)
            display: flex
            justify-content: flex-start
            padding: 11px 10px
            border-radius: 5px
            color: $black
            font-size: 15px
            font-weight: 500
            font-family: $font-family
            background-color: rgba($black-rgb, 0.05)