@import src/styles/variables
@import src/styles/colors


@mixin navigation-button
    padding: 49px !important
    position: absolute
    left: 0
    transition: all 350ms $animationCurve 550ms
    background-color: transparent

    &.hide
        opacity: 0
        pointer-events: none


    .button-icon
        color: $base-color


.powerbi-pages
    width: 100%
    height: 100vh
    color: $black

    .nav
        padding: 0 49px
        width: 100%
        height: 0
        top: 0
        display: flex
        justify-content: space-between
        align-items: center
        padding: 50px
        position: fixed
        border-bottom: 1px solid $black
        overflow: hidden
        z-index: 999999999
        background-color: $white
        @include disable-selecting

        &:after
            display: block
            content: ""
            width: 100%
            height: 100%
            position: absolute
            top: 0
            left: 0
            background: repeating-linear-gradient(45deg, rgba($black-rgb, .05) 1px, rgba($black-rgb, .05) 2px, transparent 2px, transparent 7px)
            z-index: -1


        .left-side
            display: flex
            justify-content: center
            align-items: center
            gap: 15px

            .logo-container
                transition: all 750ms $animationCurve
                transform: translateY(4px)

                .logo
                    cursor: pointer

                h1
                    color: $yellow
                    line-height: 20px

                    span
                        color: $black
                        font-size: 15px
                        letter-spacing: 6.65px

            .select-report

                > div

                    .button
                        padding: 12px 15px !important
                        border-radius: 10px
                        background-color: transparent

                        &:hover
                            background-color: rgba($black-rgb, .1)

                &.active
                    .button
                        background-color: rgba($black-rgb, .1)
        .right-side
            display: flex
            gap: 15px
            justify-content: center
            align-items: center

            .button
                border-radius: 10px

                &.manage-accounts
                    background-color: rgba($black-rgb, .02)


                &.icon-button
                    padding: 0px !important
                    height: 43px
                    width: auto
                    aspect-ratio: 1/1

        .customer-name
            width: 0
            height: 0
            opacity: 0
            position: absolute
            display: flex
            justify-content: center
            align-items: center
            transition: all 750ms $animationCurve

            &.show
                opacity: 1

            > p
                min-width: max-content
                $border-width: 1px
                letter-spacing: 3px
                font-size: 50px
                font-weight: 900
                color: $white
                font-family: $font-family-roboto
                -webkit-text-fill-color: $white /* Will override color (regardless of order) */
                -webkit-text-stroke-width: $border-width
                -webkit-text-stroke-color: $base-color

    .powerbi-pages-container
        height: calc(100% - 105px)
        transform: translateY(100px)
        width: 100%
        overflow: auto

        &.modal-is-open
            pointer-events: none
            opacity: .5

        .switch-animation
            display: contents
            opacity: 0

            .powerbi-page
                height: 100%
                width: 100%
                position: relative
            &.dive-in
                .powerbi-page
                    animation: diveIn 550ms $animationCurve forwards

            &.dive-out
                .powerbi-page
                    pointer-events: none
                    animation: diveOut 550ms $animationCurve forwards


    &.login
        .powerbi-pages-container
            overflow: hidden
            transform: translateY(0)


@keyframes diveIn
    0%
        transform: translateY(-50px)
        opacity: 0

    100%
        transform: translateY(0)
        opacity: 1

@keyframes diveOut
    0%
        transform: translateY(0)
        opacity: 1

    100%
        transform: translateY(50px)
        opacity: 0