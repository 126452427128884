@import "src/styles/colors"
@import "src/styles/variables"

.tv-tool-modal
  padding: 15px
  height: initial
  max-height: calc(100% - 30px)
  min-width: 350px
  position: fixed
  box-shadow: 0 0 0 transparent
  border-radius: 10px
  top: 50%
  left: 50%
  opacity: 0
  pointer-events: none
  transform: translateX(-50%) translateY(-50%) scale(.95)
  transition: all 750ms $animationCurve
  background-color: $nav-background-color
  z-index: 500
  border: 1px solid $nav-border-color
  display: flex
  flex-direction: column

  &.open
    opacity: 1
    pointer-events: auto
    box-shadow: 0 15px 50px rgba(0, 0, 0, .05)
    transform: translateX(-50%) translateY(-50%) scale(1)

  hr
    margin: 5px

  &.disable-scrolling
    pointer-events: none

  .tv-tool-modal-headline
    max-height: 60px
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    font-size: 20px
    color: $black
    font-weight: 500
    text-align: center

  .tv-tool-modal-body
    margin: 15px 15px
    color: $black
    overflow: auto

  .tv-tool-modal-bottom-buttons
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    .tv-tool-button
      background-color: $nav-background-color