@import src/styles/colors
@import src/styles/variables
@import src/pages/admin/modals/create-new-account/styles

.edit-account-modal
    @extend .create-new-account-modal

    .new-password-strength-hints
        padding: 15px 10px
        background-color: rgba($black-rgb, 0.05)
        border-radius: 5px
        @include disable-selecting

        ul
            list-style-type: none
            display: flex
            flex-direction: column
            gap: 10px

            > li
                width: 100%

                .checkbox
                    width: max-content
                    color: $red
                    border-color: transparent
                    padding: 0
                    background-color: transparent

                    .checkbox-icon
                        border-color: $red

                    &.checked
                        color: $green

                        .checkbox-icon
                            border-color: $green

                            .material-symbols-outlined
                                color: $green