@import "src/styles/colors"

.select-option-container
    position: absolute
    top: 0
    width: 100%
    min-height: max-content
    height: max-content
    max-height: max-content
    -webkit-filter: blur(0)
    transform: perspective(1px) translateY(0) translateZ(0)


    .submenu-selector
        pointer-events: inherit
        display: inline

        > div
            display: contents

    .select-option
        padding: 0 15px
        padding-left: 30px
        color: rgba(0, 0, 0, .8)
        background-color: transparent
        transition-duration: 350ms
        transition-timing-function: cubic-bezier(.4, .2, 0, 1)
        transition-property: height, opacity
        display: flex
        align-items: center
        position: relative
        cursor: pointer
        overflow: hidden
        width: calc(100% - 15px)
        height: 40px
        box-sizing: border-box
        border: 1px solid rgba($blue-rgb, 0)
        border-radius: 5px

        .option-content
            display: flex
            align-items: center
            min-width: max-content
            width: max-content
            max-width: max-content

            .option-text
                font-size: 14px
                color: rgba($black-rgb, .6)
                position: relative
                left: -15px
                transition: left 350ms cubic-bezier(.4, .2, 0, 1), opacity 350ms cubic-bezier(.4, .2, 0, 1) 350ms
                line-height: 0
                overflow: inherit

                > b
                    color: rgba($yellow-rgb, .8)
                    background: repeating-linear-gradient(45deg, rgba($yellow-rgb, .2) 1px, rgba($yellow-rgb, .2) 2px, transparent 2px, transparent 7px)
                    border: 1px solid rgba($yellow-rgb, .2)
                    border-radius: 5px

            .select-option-icon
                position: absolute
                transition: left 350ms cubic-bezier(.4, .2, 0, 1), opacity 0 cubic-bezier(.4, .2, 0, 1) 0
                width: 25px
                font-size: 15px
                color: $blue
                left: -20px
                opacity: 0

                &.submenu
                    opacity: 1
                    right: 12px
                    left: auto
                    color: $black
                    left: auto
                    font-size: 15px
                    opacity: .8
                    width: max-content

        &.loading-option
            animation: placeHolderShimmer 2s linear forwards infinite
            background-color: #f6f7f8
            background: linear-gradient(to right, rgba($black-rgb, .05) 8%, rgba($black-rgb, .2) 18%, rgba($black-rgb, .05) 33%)
            background-size: 800px 104px
            pointer-events: none

        &.default-option
            .option-text
                color: rgba($black-rgb, .6)

        &.hide-option
            opacity: 0
            pointer-events: none

            .option-text
                opacity: 0
                transition: left 350ms cubic-bezier(.4, .2, 0, 1), opacity 350ms cubic-bezier(.4, .2, 0, 1) 350ms

            .select-option-icon
                opacity: 0
                transition: left 350ms cubic-bezier(.4, .2, 0, 1), opacity 350ms cubic-bezier(.4, .2, 0, 1) 350ms

        &.selected

            .option-text
                color: $black
                left: 0

            .select-option-icon
                opacity: 1
                left: 7px

                &.submenu
                    opacity: .8
                    right: 12px
                    left: auto

        &.hide-option
            pointer-events: none

        &:hover, &.hover-effect
            background-color: $blue-light

            .option-text
                color: $blue


@keyframes placeHolderShimmer
  0%
    background-position: -800px 0
  100%
    background-position: 800px 0