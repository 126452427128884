@import src/styles/colors
@import src/styles/variables

.powerbi-page.admin
  padding: 30px 50px
  padding-bottom: 0
  transition: all 350ms $animationCurve
  @include disable-selecting

  .content
    display: flex
    flex-direction: column
    overflow: hidden

    &.modal-is-open
      pointer-events: none
      opacity: .5

    .header-navbar
      display: flex
      justify-content: space-between
      align-items: center
      margin-bottom: 30px
      @include disable-selecting

      &-buttons-container
        display: flex
        gap: 20px

    .accounts
      overflow: auto
      display: flex
      flex-direction: column
      gap: 15px
      height: 100%
      overflow: auto

      .account
        border: 1px solid rgba($black-rgb, .5)
        border-radius: 10px
        display: flex
        justify-content: space-between
        align-items: center
        height: 70px
        padding-left: 30px
        position: relative
        overflow: hidden

        .username
          display: flex
          justify-content: center
          align-items: center
          gap: 20px

          .material-symbols-outlined
            display: flex
            justify-content: center
            align-items: center
            width: 0

        .action-buttons
          display: flex
          justify-content: center
          align-items: center
          height: 100%
          width: min-content
          position: absolute
          right: 0
          top: 0
          padding: 10px
          gap: 10px

          .button
            border-radius: 5px
            padding: 0
            height: 100%
            width: inherit
            aspect-ratio: 1/1

            &.block-unblock-button
              aspect-ratio: auto
              width: auto
              padding: 0 15px

              .button-icon
                &.left
                  padding-left: 7px

            &:first-child

            &:last-child

    .badge
      color: white
      padding: 4px 8px
      text-align: center
      border-radius: 5px
      &-green
        background-color: green
      &-red
        background-color: red
