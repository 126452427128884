@import src/styles/colors

.powerbi-page.page-404
    display: flex
    justify-content: center
    align-items: center
    height: 100%

    > h1
        display: flex
        justify-content: center
        align-items: center
        aspect-ratio: 1/1
        width: 50vw
        height: 50vw
        font-size: 20vw
        color: $yellow

        i
            color: $black