@import "src/styles/colors"

.select
    position: relative
    display: flex
    justify-content: center
    align-items: center
    pointer-events: auto

    &:after
        $box-size: 2.5px
        content: ""
        display: block
        width: 0
        height: 0
        padding: $box-size
        position: absolute
        top: $box-size * 2
        left: $box-size * 2
        background-color: $blue
        border-radius: 10px
        opacity: 0
        transition: opacity 350ms cubic-bezier(.4, .2, 0, 1)

    &.options-selected, &.show-dot
        &:after
            opacity: 1

    > span
        cursor: pointer
