$padX: 24
$padY: 24

$margin: 20px
$padding: 10px
$buttons-border-radius: 5px

$font-family: 'Montserrat', sans-serif
$font-family-roboto: 'Roboto', sans-serif

$durationNumber: 550
$duration: $durationNumber + ms

$animationCurve: cubic-bezier(0.4, 0.2, 0, 1)

@mixin disable-selecting
    -webkit-touch-callout: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0)