$dark-theme: var(--theme)

$background-color: var(--white)

$white: var(--white)
$black: var(--black)

$grey: var(--grey)
$green: var(--green)
$red: var(--red)
$blue: var(--blue)
$yellow: var(--yellow)
$orange: var(--orange)

$grey-light: var(--grey-light)
$green-light: var(--green-light)
$red-light: var(--red-light)
$blue-light: var(--blue-light)
$yellow-light: var(--yellow-light)
$orange-light: var(--orange-light)

$grey-dark: var(--grey-dark)
$green-dark: var(--green-dark)
$red-dark: var(--red-dark)
$blue-dark: var(--blue-dark)
$yellow-dark: var(--yellow-dark)
$orange-dark: var(--orange-dark)

$white-rgb: var(--white-rgb)
$black-rgb: var(--black-rgb)

$grey-rgb: var(--grey-rgb)
$green-rgb: var(--green-rgb)
$red-rgb: var(--red-rgb)
$blue-rgb: var(--blue-rgb)
$yellow-rgb: var(--yellow-rgb)
$orange-rgb: var(--orange-rgb)

$grey-light-rgb: var(--grey-light-rgb)
$green-light-rgb: var(--green-light-rgb)
$red-light-rgb: var(--red-light-rgb)
$blue-light-rgb: var(--blue-light-rgb)
$yellow-light-rgb: var(--yellow-light-rgb)
$orange-light-rgb: var(--orange-light-rgb)

$grey-dark-rgb: var(--grey-dark-rgb)
$green-dark-rgb: var(--green-dark-rgb)
$red-dark-rgb: var(--red-dark-rgb)
$blue-dark-rgb: var(--blue-dark-rgb)
$yellow-dark-rgb: var(--yellow-dark-rgb)
$orange-dark-rgb: var(--orange-dark-rgb)

$logo-color: var(--logo-color)
$base-color: var(--base-color)
$base-color-accent: var(--base-color-accent)
$base-color-rgb: var(--base-color-rgb)
$base-color-accent-rgb: var(--base-color-accent-rgb)


$selection-color: var(--selection-color)
$selection-text-color: var(--selection-text-color)
$border-color: var(--border-color)
$filter-text-background-color: var(--filter-text-background-color)
$filter-text-color: var(--filter-text-color)
$border-color-dark: var(--border-color-dark)
$nav-background-color: var(--nav-background-color)
$nav-box-shadow-color: var(--nav-box-shadow-color)
$nav-border-color: var(--nav-border-color)
$text-field-button-border-color: var(--text-field-button-border-color)
$text-field-button-border-alpha: var(--text-field-button-border-alpha)
$admin-panel-right-selector-background-effect-opacity: var(--admin-panel-right-selector-background-effect-opacity)
